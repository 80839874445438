import React, {useEffect} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {AboutPage} from "@atttomyx/react-components";
import {SharedImagePage} from "./components";
import {appService, authService} from "./services";
import {APP_ID_APPLE, APP_ID_GOOGLE, APP_NAME, APP_TAG_LINE, PAGE_SHARED, PATH_DEEP_LINK} from "./constants";
import icon from "./icon.png";

const noop = () => {};

const App = (props) => {
    const { snackbar } = props;

    useEffect(() => {
        appService.ensureOnLatestVersion(false, null);
        authService.setupAxiosInterceptors(noop);
    }, []);

    return (
        <div className="app">
            <div className="content">
                <Switch>
                    <Route
                        path={`${PATH_DEEP_LINK}${PAGE_SHARED}/:id`}
                        render={(props) =>
                            <SharedImagePage
                                {...props}
                                snackbar={snackbar}
                            />}
                    />
                    <Route>
                        <AboutPage
                            src={icon}
                            appName={APP_NAME}
                            appDescription={APP_TAG_LINE}
                            appIdApple={APP_ID_APPLE}
                            appIdGoogle={APP_ID_GOOGLE}
                            appService={appService}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default withRouter(App);
