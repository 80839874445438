import {objects} from "@atttomyx/shared-utils";
import {base} from "./base";

export const light = (branding) => {
    return objects.deepMerge(base(branding), {
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FCFCFB",
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FCFCFB",
                },
            }
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#EFEFEF",
                },
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    border: "1px solid #E2E2E1",
                    backgroundColor: "#FCFCFB",
                    "&:hover": {
                        backgroundColor: "#FFFFFF",
                    },
                    "&$focused": {
                        backgroundColor: "#FFFFFF",
                    },
                },
            }
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    '& [class*="Mui-disabled"]': {
                        color: "rgba(0, 0, 0, 0.26)",
                    },
                },
            }
        },
    });
};
