export const base = (branding) => {
    return {
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    bottom: "68px !important",
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    justifyContent: "center",
                },
            },
        },
    }
};
