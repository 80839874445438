import axios from 'axios';
import axiosRetry from 'axios-retry';
import {timezones} from "@atttomyx/react-utils";
import {time} from "@atttomyx/shared-constants";
import {API_BASE_URL,} from "../constants";

let source = axios.CancelToken.source();

axios.defaults.timeout = time.SECONDS_IN_MINUTE * time.MILLISECONDS_IN_SECOND;
axios.defaults.baseURL = API_BASE_URL;

axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isSafeRequestError,
});

export const setupAxiosInterceptors = onUnauthenticated => {
    const onRequestSuccess = config => {
        const tz = timezones.getTimeZone();

        if (tz) {
            config.headers["x-tz"] = tz;
        }

        config.cancelToken = source.token;

        return config;
    };

    const onResponseSuccess = response => response;

    const onResponseError = err => {
        const status = err.status || (err.response ? err.response.status : 0);

        if (status === 403 || status === 401) {
            onUnauthenticated();
        }

        return Promise.reject(err);
    };

    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export const cancelAllRequests = (reason) => {
    source.cancel(reason);
    source = axios.CancelToken.source();
};
