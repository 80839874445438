import axios from "axios";

export const loadShare = (shareId, success, failure) => {
    const url = `/api/v1/share/${shareId}/`;

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json.share);
    })
    .catch(failure);
};
